<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline">Edit Main Details</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="main-details-form">
          <v-text-field
            label="Title"
            v-model="fields.title"
            background-color="white"
            outlined
            dense
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>
          <v-textarea
            label="Intro Text"
            v-model="fields.intro_text"
            rows="3"
            background-color="white"
            outlined
            dense
            :error="errors.hasOwnProperty('intro_text')"
            :error-messages="errors['intro_text']"
          ></v-textarea>
          <v-text-field
            label="Google Map"
            v-model="fields.google_map"
            background-color="white"
            outlined
            dense
            :error="errors.hasOwnProperty('google_map')"
            :error-messages="errors['google_map']"
          ></v-text-field>
          <v-textarea
            label="Extras Summary"
            v-model="fields.extras_summary"
            rows="3"
            background-color="white"
            outlined
            dense
            :error="errors.hasOwnProperty('extras_summary')"
            :error-messages="errors['extras_summary']"
          ></v-textarea>
          <v-textarea
            label="Drumgolf Summary"
            v-model="fields.drumgolf_summary_text"
            rows="3"
            background-color="white"
            outlined
            dense
            :error="errors.hasOwnProperty('drumgolf_summary_text')"
            :error-messages="errors['drumgolf_summary_text']"
          ></v-textarea>
          <v-textarea
            label="Accommodation Summary"
            v-model="fields.accommodation_summary"
            rows="3"
            background-color="white"
            outlined
            dense
            :error="errors.hasOwnProperty('accommodation_summary')"
            :error-messages="errors['accommodation_summary']"
          ></v-textarea>
          <v-textarea
            label="Golf Summary"
            v-model="fields.golf_summary"
            rows="3"
            background-color="white"
            outlined
            dense
            :error="errors.hasOwnProperty('golf_summary')"
            :error-messages="errors['golf_summary']"
          ></v-textarea>
          <v-textarea
            label="Transport Summary"
            v-model="fields.transport_summary"
            rows="8"
            background-color="white"
            outlined
            dense
            :error="errors.hasOwnProperty('transport_summary')"
            :error-messages="errors['transport_summary']"
          ></v-textarea>
          <v-select
            label="Restaurant Regions"
            v-model="fields.restaurant_regions"
            :items="regions"
            item-text="name"
            item-value="id"
            background-color="white"
            multiple
            outlined
            dense
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="main-details-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        title: "",
        intro_text: "",
        google_map: "",
        extras_summary: "",
        drumgolf_summary_text: "",
        accommodation_summary: "",
        golf_summary: "",
        transport_summary: "",
        restaurant_regions: [],
      },
      errors: {},
    };
  },

  computed: {
    itinerary() {
      return this.$store.state.drum.tours["itinerary"];
    },

    regions() {
      return this.$store.state.drum.admin["restaurantRegions"];
    },
  },

  methods: {
    openForm: function() {
      this.fields.title = this.itinerary.title;
      this.fields.intro_text = this.itinerary.intro_text;
      this.fields.google_map = this.itinerary.google_map;
      this.fields.extras_summary = this.itinerary.extras_summary;
      this.fields.drumgolf_summary_text = this.itinerary.drumgolf_summary_text
        ? this.itinerary.drumgolf_summary_text
        : "Comprehensive Tour Management Service - including liaison with hotels and golf clubs, on call assistance at all times and information pack.";
      this.fields.accommodation_summary = this.itinerary.accommodation_summary;
      this.fields.golf_summary = this.itinerary.golf_summary;
      this.fields.transport_summary = this.itinerary.transport_summary;

      this.fields.transport_summary = this.itinerary.transport_summary
        ? this.itinerary.transport_summary
        : "Rental Car transportation\r\n\r\nWe have based your quotation on the rental of a mid size car with automatic transmission. On departure day you must return your vehicle no later than the time you collected it on arrival day. Failure to do this will result in extra charges. Moreover, please advise if you will be travelling into one airport and departing from another at the time of booking as this will incur a surcharge.\r\n\r\nExecutive Mini Coach\r\n\r\nWe have based your quotation on a Chauffeur-driven executive mini coach, which features, refrigeration/ coolbox and leather upholstery. Our drivers are extremely conscientious and professional. They not only ensure that you get to your destination on time, but they act as a guide throughout the tour and are happy to assist in all your day to day arrangements, including sightseeing excursions.\r\n\r\nThe quote is based on 1 vehicle transfer on day of arrival and 1 vehicle transfer on the day of departure. If there are multiple flights for 1 group, and the vehicle cannot accommodate all airport transfers, additional transfers will cost extra.\r\n\r\nPlease also note that your driver is at your service throughout daytime hours for transfers to golf courses, running errands, and for sightseeing etc. Drivers are obligated by law to take rest periods, which are measured by time on duty rather than drive time. They are happy to transfer you to a restaurant at the end of the day but we would request that you arrange a taxi for the return trip.\r\n\r\nTransfers\r\n\r\nThe quotation includes vehicle transfers from and to the airport on arrival & departure days and specific transfers to Golf Course as stipulated in the quotation. The Driver will have prearranged pick up times to and from the courses.";

      this.fields.restaurant_regions = this.itinerary.restaurant_regions.map(
        r => {
          return r.id;
        }
      );

      this.dialog = true;
    },

    resetForm: function() {
      this.dialog = false;
      this.loading = false;
      this.fields = {
        title: "",
        intro_text: "",
        google_map: "",
        extras_summary: "",
        drumgolf_summary_text: "",
        accommodation_summary: "",
        golf_summary: "",
        transport_summary: "",
        restaurant_regions: [],
      };
      this.errors = {};
    },

    saveForm: function() {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const itineraryId = this.$route.params.itineraryId;
      this.loading = true;
      this.errors = {};

      this.$store
        .dispatch("drum/tours/saveItinerary", {
          appId,
          tourId,
          itineraryId,
          isEditing: true,
          fields: this.fields,
        })
        .then(() => {
          this.resetForm();
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
