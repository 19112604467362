<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Main Details</h2>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn
            small
            depressed
            color="blue white--text"
            @click="$refs.mainDetailsForm.openForm()"
          >
            <v-icon small left>mdi-pencil</v-icon>Edit
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Title
          </div>
          <v-divider></v-divider>
          <div class="pa-2" v-html="itinerary.title"></div>
        </v-sheet>
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Intro Text
          </div>
          <v-divider></v-divider>
          <div
            class="pa-2"
            v-if="itinerary.intro_text"
            style="white-space: pre-wrap"
            v-html="itinerary.intro_text"
          ></div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Google Map
          </div>
          <v-divider></v-divider>
          <div class="pa-2" v-if="itinerary.google_map">
            {{ itinerary.google_map }}
          </div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Extras Summary
          </div>
          <v-divider></v-divider>
          <div
            class="pa-2"
            v-if="itinerary.extras_summary"
            style="white-space: pre-wrap"
            v-html="itinerary.extras_summary"
          ></div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Drumgolf Summary
          </div>
          <v-divider></v-divider>
          <div
            class="pa-2"
            v-if="itinerary.drumgolf_summary_text"
            style="white-space: pre-wrap"
            v-html="itinerary.drumgolf_summary_text"
          ></div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
      </v-col>
      <v-col cols="6">
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Accommodation Summary
          </div>
          <v-divider></v-divider>
          <div
            class="pa-2"
            v-if="itinerary.accommodation_summary"
            style="white-space: pre-wrap"
            v-html="itinerary.accommodation_summary"
          ></div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Golf Summary
          </div>
          <v-divider></v-divider>
          <div
            class="pa-2"
            v-if="itinerary.golf_summary"
            style="white-space: pre-wrap"
            v-html="itinerary.golf_summary"
          ></div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Transport Summary
          </div>
          <v-divider></v-divider>
          <div
            class="pa-2"
            v-if="itinerary.transport_summary"
            style="white-space: pre-wrap"
            v-html="itinerary.transport_summary"
          ></div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
        <v-sheet outlined rounded class="mb-4">
          <div class="pa-2 text-subtitle-1 font-weight-bold grey lighten-4">
            Restaurant Regions
          </div>
          <v-divider></v-divider>
          <div class="pa-2" v-if="itinerary.restaurant_regions.length > 0">
            <ul>
              <li
                v-for="region in itinerary.restaurant_regions"
                v-bind:key="region.id"
              >
                {{ region.name }}
              </li>
            </ul>
          </div>
          <div class="pa-2 text--disabled" v-else>Empty</div>
        </v-sheet>
      </v-col>
    </v-row>
    <MainDetailsForm ref="mainDetailsForm" />
  </div>
</template>

<script>
import MainDetailsForm from "../../components/MainDetailsForm";

export default {
  components: {
    MainDetailsForm,
  },

  computed: {
    tour() {
      return this.$store.state.drum.tours["tour"];
    },

    itinerary() {
      return this.$store.state.drum.tours["itinerary"];
    },
  },
};
</script>
